import React, {useEffect} from 'react'
import Card from '../../hoc/Card/Card'
import Button from '../../hoc/Button/Button'
import Aux from '../../hoc/Aux-component'
import classes from '../../Global.module.css'
import Accordion from '../../components/Accordion/Accordion'
import {useMediaQuery} from '../../hooks'
import Banner from "../../hoc/Banner/Banner";

const ChoiceHotels = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        document.title = "Precert Accolade | Choice Hotels"
    }, []);
    return (
        <Aux>
            <Banner>
                <h2>Things you should know</h2>
                <p><span style={{fontWeight: 'bold'}}>Bariatric, Joint and Spine Surgery - Center of Excellence Requirement</span> <br/> Bariatric, Joint and Spine surgeries need to be obtained through Carrum Health or there is no coverage. Please contact Accolade Provider Services at <a href={`tel:866-386-0165`}>866-386-0165</a> for more information.</p>
                <p><span style={{fontWeight: 'bold'}}>Precertification Requirements</span> <br/> See precertification
                    list below for list of services that require precertification.</p>
            </Banner>
            <div className={classes.Global}>
                <Card style={styles.container(isDesktop)}>
                    <h1>Choice Hotels Precertification List <br/>Effective 1/1/25</h1>
                    <p>Precertification is a determination of medical necessity only, and <span>does not involve matters of claim payment, eligibility, coverage and the type and/or availability of benefits.</span>
                    </p>
                    <p>The establishment and construct of a precertification list is solely the responsibility of
                        customer and/or the applicable plan sponsor (and not Accolade).
                        The design and implementation of a precertification list should be made only after obtaining the
                        advice of customer’s legal and benefit professionals,
                        to include customer’s full review of applicable health and welfare benefit plan terms and
                        conditions as well as any applicable laws (for example, the Employee Retirement Income Security
                        Act of 1974, and the Health Parity and Addiction Equity Act of 2008, as amended).
                        Accolade will have no responsibility for reviewing applicable plan documents, advising customer
                        with respect to its precertification list or for customer’s compliance with applicable laws
                        related to the same.
                        No representation or warranty is given with respect to this document, which is offered
                        as-is.</p>
                    <div style={{fontWeight: 'bold'}}>Precertification requirements should be implemented only for those
                        services listed within the Summary Plan Description as requiring Prenotification or
                        Precertification and not defined as excluded.
                    </div>
                    <Accordion label='All Inpatient Admissions'>
                        <ul>
                            <li>Acute</li>
                            <li>Long-Term Acute Care</li>
                            <li>Rehabilitation</li>
                            <li>Mental Health / Substance Use Disorder</li>
                            <li>Transplant</li>
                            <li>Skilled Nursing Facility</li>
                            <li>Residential Treatment Facility</li>
                            <li>Obstetric – Prenotification only (precertification only required if days exceed Federal
                                mandate)
                            </li>
                        </ul>
                    </Accordion>
                    <div style={{fontWeight: 'bold'}}>Precertification for Inpatient and Outpatient procedures that
                        could be considered Experimental / Investigational
                    </div>
                    <Accordion label='Outpatient and Physician – Surgery'>
                        <ul>
                            <li>
                                <strong>Carrum COE</strong> (services must be approved by and rendered by Carrum)
                                <br/>
                                Limited to specific CPT codes; reference Carrum Carve Out List
                                <ul>
                            <li>Bariatric</li>
                            <li>Joint Replacement (Hip and Knee)</li>
                            <li>Spinal Fusion </li>
                                </ul>
                            </li>

                            <li>All joint replacement surgeries</li>
                            <li>Thyroidectomy, Partial or Complete</li>
                            <li>Prostate or Ovary Removal – include Open Prostatectomy / Oopherectomy</li>
                            <li>Back Surgeries and hardware related to surgery</li>
                            <li>Osteochondral Allograft, knee</li>
                            <li>Hysterectomy (including prophylactic)</li>
                            <li>Autologous chondrocyte implantation, Carticel</li>
                            <ul>
                                <li>Blepharoplasty</li>
                                <li>Cervicoplasty (neck lift)</li>
                            </ul>
                            <li>Transplant (excluding cornea)</li>
                            <li>Balloon sinuplasty</li>
                            <li>Sleep apnea related surgeries, limited to:
                                <ul>
                                    <li>Radiofrequency ablation (Coblation, Somnoplasty)</li>
                                    <li>Uvulopalatopharyngoplasty (UPPP) (including laser-assisted procedures)</li>
                                </ul>
                            </li>
                            <li>Potentially Cosmetic Procedures:
                                <ul>
                                    <li>Abdominoplasty</li>
                                    <li>Facial skin lesions (Phototherapy, laser therapy - excluding MOHS)</li>
                                    <li>Hernia repair, abdominal and incisional (only when associated with a cosmetic
                                        procedure)
                                    </li>
                                    <li>IDET (thermal intradiscal procedures)</li>
                                    <li>Liposuction/lipectomy</li>
                                    <li>Mammoplasty, augmentation and reduction (including removal of implant)</li>
                                    <li>Mastectomy (including gynecomastia and prophylactic)</li>
                                    <li>Morbid obesity procedures</li>
                                    <li>Orthognathic procedures (e.g. Genioplasty, LeFort osteotomy, Mandibular ORIF,
                                        TMJ)
                                    </li>
                                    <li>Otoplasty</li>
                                    <li>Panniculectomy</li>
                                    <li>Rhinoplasty</li>
                                    <li>Rhytidectomy</li>
                                    <li>Scar revisions</li>
                                    <li>Septoplasty</li>
                                    <li>Varicose vein surgery/sclerotherapy</li>
                                </ul>
                            </li>
                        </ul>
                    </Accordion>
                    <Accordion label='Outpatient and Physician – Diagnostic Services'>
                        <ul>
                            <li>MRI</li>
                            <li>MRA</li>
                            <li>PET</li>
                            <li>Capsule endoscopy</li>
                            <li>Genetic Testing (including BRCA)</li>
                            <li>Sleep Study (facility based)</li>
                        </ul>
                    </Accordion>
                    <Accordion label='Outpatient and Physician – Continuing Care Services'>
                        <ul>
                            <li>Radiation Therapy</li>
                            <li>Hyperbaric Oxygen</li>
                            <li>Home Health Care related to occupational therapy, physical therapy, speech therapy and
                                skilled nursing care done in the home
                            </li>
                            <li>Durable Medical Equipment, limited to electric/motorized scooters or wheelchairs and
                                pneumatic compression devices
                            </li>
                            <li>DME {'>'}$1K (excludes disposable supplies)</li>
                        </ul>
                    </Accordion>
                    <Accordion label='Medications, Injections and Infusions – including chemotherapy (covered under the medical plan) '>
                    </Accordion>
                    <Accordion label='Medical Evacuation'>
                        <ul>
                            <li>Air Ambulance for non-emergent transportation</li>
                        </ul>
                    </Accordion>
                </Card>
                <h1>Ready to start a new pre-certification request?</h1>
                <Button onClick={() => {
                    window.location.href = 'https://accolade.getprecert.com/(S(cbykgvb0qn2hh32kfks0etan))/default.aspx';
                }}
                        style={{cursor: 'pointer'}}
                        id={"start"}>Start Request</Button>
            </div>
        </Aux>
    )
}

const styles = {
    container: isDesktop => ({
        padding: isDesktop ? '80px 170px' : '10px 40px 30px 29px',
        marginBottom: isDesktop ? '150px' : '90px'
    })
};

export default ChoiceHotels;