import Layout from "./components/Layout/Layout";
import DayAndZimmermann from "./pages/DayAndZimmermann/DayAndZimmermann";
import Kindred from "./pages/Kindred/Kindred";
import { ALLOWED_ROUTES } from "./constants";
import AAA from "./pages/AAA/AAA";
import Amplify from "./pages/Amplify/Amplify";
import Boart from "./pages/Boart/Boart";
import L3Harris from "./pages/L3Harris/L3Harris";
import Origlio from "./pages/Origlio/Origlio";
import Faf from "./pages/Faf/Faf";
import Hebrew from "./pages/Hebrew SeniorLife/Hebrew";
import Honickman from "./pages/Honickman/Honickman";
import Hunter from "./pages/Hunter/Hunter";
import Cetera from "./pages/Cetera/Cetera";
import Crowley from "./pages/Crowley/Crowley";
import Cofw from "./pages/Cofw/Cofw";
import Cogentrix from "./pages/Cogentrix/Cogentrix";
import Rsc from "./pages/Rsc/Rsc";
import SafeAuto from "./pages/SafeAuto/SafeAuto";
import LermanHoldings from "./pages/LermanHoldings/LermanHoldings";
import Beacon from "./pages/Beacon/Beacon";
import TKWW from "./pages/TKWW/TKWW";
import Wagman from "./pages/Wagman/Wagman";
import Gresham from "./pages/Gresham/Gresham";
import ToryBurch from "./pages/ToryBurch/ToryBurch";
import PayPal from "./pages/PayPal/PayPal";
import { useMemo } from "react";
import Sauder from "./pages/Sauder/Sauder";
import LassondePappas from "./pages/LassondePappas/LassondePappas";
import Peraton from "./pages/Peraton/Peraton";
import Hyatt from "./pages/Hyatt/Hyatt";
import Graymont from "./pages/Graymont/Graymont";
import ReverePlastics from "./pages/ReverePlastics/ReverePlastics";
import RedThread from "./pages/RedThread/RedThread";
import Accolade from "./pages/Accolade/Accolade";
import Cbiz from "./pages/Cbiz/Cbiz";
import HealthAndWellbeing from "./pages/HealthAndWellbeing/HealthAndWellbeing";
import ChoiceHotels from "./pages/ChoiceHotels/ChoiceHotels";
import Apptio from "./pages/Apptio/Apptio";
import SevenEleven from "./pages/7Eleven/7Eleven";
import Safran from "./pages/Safran/Safran";
import NevadaGoldMines from "./pages/NevadaGoldMines/NevadaGoldMines";
import Hilliard from "./pages/Hilliard/Hilliard";
import Unisys from "./pages/Unisys/Unisys";
import FerttitaEntertainment from "./pages/FerttitaEntertainment/FerttitaEntertainment";
import UniversityCalifornia from "./pages/UniversityCalifornia/UniversityCalifornia";
import Greif from "./pages/Greif/Greif";
import RevSpring from "./pages/RevSpring/RevSpring";
import SimpliSafe from "./pages/SimpliSafe/SimpliSafe";
import Gentiva from "./pages/Gentiva/Gentiva";
import StandardAero from "./pages/StandardAero/StandardAero";
import Enlyte from "./pages/Enlyte/Enlyte";
import Enercon from "./pages/Enercon/Enercon";
import LighthouseAutismCenter from "./pages/LighthouseAutismCenter/LighthouseAutismCenter";
import RedRiverTechnology from "./pages/RedRiverTechnology/RedRiverTechnology";
import LeadVenture from "./pages/LeadVenture/LeadVenture";
import PTAPlastics from "./pages/PTAPlastics/PTAPlastics";
import RocketSoftware from "./pages/RocketSoftware/RocketSoftware";
import DwyerOmega from "./pages/DwyerOmega/DwyerOmega";
import PediatricAssociates from "./pages/PediatricAssociates/PediatricAssociates";
import Wawa from "./pages/Wawa/Wawa";
import Banyan from "./pages/Banyan/Banyan";

function App() {
  const currentRoute = window.location.pathname.toLowerCase();

  const currentRouteAllowed = useMemo(
    () => Object.values(ALLOWED_ROUTES).includes(currentRoute),
    [currentRoute]
  );

  if (!currentRouteAllowed) return null;

  return (
    <>
      <Layout currentRoute={currentRoute}>
        {currentRoute === ALLOWED_ROUTES.KINDRED && <Kindred />}

        {currentRoute === ALLOWED_ROUTES.AAA && <AAA />}

        {currentRoute === ALLOWED_ROUTES.AMPLIFY && <Amplify />}

        {currentRoute === ALLOWED_ROUTES.HEBREW && <Hebrew />}

        {currentRoute === ALLOWED_ROUTES.CROWLEY && <Crowley />}

        {currentRoute === ALLOWED_ROUTES.SAFEAUTO && <SafeAuto />}

        {currentRoute === ALLOWED_ROUTES.ORIGLIO && <Origlio />}

        {currentRoute === ALLOWED_ROUTES.BEACON && <Beacon />}

        {currentRoute === ALLOWED_ROUTES.TKWW && <TKWW />}

        {currentRoute === ALLOWED_ROUTES.SAUDER && <Sauder />}

        {currentRoute === ALLOWED_ROUTES.CETERA && <Cetera />}

        {currentRoute === ALLOWED_ROUTES.COGENTRIX && <Cogentrix />}

        {currentRoute === ALLOWED_ROUTES.CITY_OF_FORT_WORTH && <Cofw />}

        {currentRoute === ALLOWED_ROUTES.FIRST_AMERICAN_FIN && <Faf />}

        {currentRoute === ALLOWED_ROUTES.HUNTER_DOUGLAS && <Hunter />}

        {currentRoute === ALLOWED_ROUTES.HONICKMAN && <Honickman />}

        {currentRoute === ALLOWED_ROUTES.REMEDI_SENIOR_CARE && <Rsc />}

        {currentRoute === ALLOWED_ROUTES.LERMAN_HOLDINGS && <LermanHoldings />}

        {currentRoute === ALLOWED_ROUTES.BOART_LONGYEAR && <Boart />}

        {currentRoute === ALLOWED_ROUTES.LASSONDE && <LassondePappas />}

        {currentRoute === ALLOWED_ROUTES.PERATON && <Peraton />}

        {currentRoute === ALLOWED_ROUTES.HYATT_HOTELS_CORPORATION && <Hyatt />}

        {currentRoute === ALLOWED_ROUTES.GRAYMONT && <Graymont />}

        {currentRoute === ALLOWED_ROUTES.REVERE_PLASTICS && <ReverePlastics />}

        {currentRoute === ALLOWED_ROUTES.WAGMAN && <Wagman />}

        {currentRoute === ALLOWED_ROUTES.GRESHAM && <Gresham />}

        {currentRoute === ALLOWED_ROUTES.TORY_BURCH && <ToryBurch />}

        {currentRoute === ALLOWED_ROUTES.RED_THREAD && <RedThread />}

        {currentRoute === ALLOWED_ROUTES.PAYPAL && <PayPal />}

        {currentRoute === ALLOWED_ROUTES.ACCOLADE && <Accolade />}

        {currentRoute === ALLOWED_ROUTES.CBIZ && <Cbiz />}

        {[
            ALLOWED_ROUTES.GALLO_WINERY,
            ALLOWED_ROUTES.HEALTH_AND_WELLBEING
        ].includes(currentRoute) && <HealthAndWellbeing />}

        {currentRoute === ALLOWED_ROUTES.CHOICE_HOTELS && <ChoiceHotels />}

        {currentRoute === ALLOWED_ROUTES.APPTIO && <Apptio />}

        {currentRoute === ALLOWED_ROUTES.SEVEN_ELEVEN && <SevenEleven />}

        {currentRoute === ALLOWED_ROUTES.SAFRAN && <Safran />}

        {currentRoute === ALLOWED_ROUTES.NEVADA_GOLD_MINES && (
          <NevadaGoldMines />
        )}

        {currentRoute === ALLOWED_ROUTES.HILLIARD && <Hilliard />}

        {currentRoute === ALLOWED_ROUTES.UNISYS && <Unisys />}

        {[
            ALLOWED_ROUTES.LANDRYS,
            ALLOWED_ROUTES.FERTTITA_ENTERTAINMENT,
        ].includes(currentRoute) && <FerttitaEntertainment />}

        {currentRoute === ALLOWED_ROUTES.UNIVERSITY_CALIFORNIA && (
          <UniversityCalifornia />
        )}

        {currentRoute === ALLOWED_ROUTES.GREIF && <Greif />}

        {currentRoute === ALLOWED_ROUTES.REV_SPRING && <RevSpring />}

        {currentRoute === ALLOWED_ROUTES.SIMPLI_SAFE && <SimpliSafe />}

        {currentRoute === ALLOWED_ROUTES.GENTIVA && <Gentiva />}

        {currentRoute === ALLOWED_ROUTES.STANDARD_AERO && <StandardAero />}

        {currentRoute === ALLOWED_ROUTES.ENLYTE && <Enlyte />}

        {currentRoute === ALLOWED_ROUTES.ENERCON && <Enercon />}

        {currentRoute === ALLOWED_ROUTES.BANYAN && <Banyan />}

        {[
          ALLOWED_ROUTES.LIGHTHOUSE_AUTISM_CENTER_UPPERCASE,
          ALLOWED_ROUTES.LIGHTHOUSE_AUTISM_CENTER,
        ].includes(currentRoute) && <LighthouseAutismCenter />}

        {currentRoute === ALLOWED_ROUTES.RED_RIVER_TECHNOLOGY && (
          <RedRiverTechnology />
        )}

        {currentRoute === ALLOWED_ROUTES.LEAD_VENTURE && <LeadVenture />}

        {currentRoute === ALLOWED_ROUTES.PTAPLASTICS && <PTAPlastics />}

        {currentRoute === ALLOWED_ROUTES.ROCKETSOFTWARE && <RocketSoftware />}

        {currentRoute === ALLOWED_ROUTES.DWYEROMEGA && <DwyerOmega />}

        {currentRoute === ALLOWED_ROUTES.L3HARRIS && <L3Harris />}

        {currentRoute === ALLOWED_ROUTES.PEDIATRIC_ASSOCIATES && (
          <PediatricAssociates />
        )}

        {currentRoute === ALLOWED_ROUTES.DAY_AND_ZIMMERMANN && <DayAndZimmermann />}

        {currentRoute === ALLOWED_ROUTES.WAWA && <Wawa />}
      </Layout>
    </>
  );
}

export default App;
