export const ALLOWED_ROUTES = {
  AAA: "/aaa",
  AMPLIFY: "/amplify",
  CROWLEY: "/crowley",
  HEBREW: "/hebrew",
  KINDRED: "/kindred",
  SAFEAUTO: "/safeauto",
  SAUDER: "/sauder",
  TKWW: "/tkww",
  ORIGLIO: "/origlio",
  CETERA: "/cetera",
  COGENTRIX: "/cogentrix",
  CITY_OF_FORT_WORTH: "/cofw",
  FIRST_AMERICAN_FIN: "/faf",
  HUNTER_DOUGLAS: "/hunter",
  HONICKMAN: "/honickman",
  REMEDI_SENIOR_CARE: "/rsc",
  BOART_LONGYEAR: "/boart",
  BEACON: "/beacon",
  LASSONDE: "/lassonde",
  PERATON: "/peraton",
  HYATT_HOTELS_CORPORATION: "/hyatt",
  GRAYMONT: "/graymont",
  REVERE_PLASTICS: "/revere",
  WAGMAN: "/wagman",
  GRESHAM: "/gresham",
  TORY_BURCH: "/toryburch",
  LERMAN_HOLDINGS: "/lerman",
  RED_THREAD: "/redthread",
  PAYPAL: "/paypal",
  ACCOLADE: "/accolade",
  CBIZ: "/cbiz",
  GALLO_WINERY: "/gallo",
  HEALTH_AND_WELLBEING: "/healthandwellbeing",
  CHOICE_HOTELS: "/choicehotels",
  APPTIO: "/apptio",
  SEVEN_ELEVEN: "/7eleven",
  SAFRAN: "/safran",
  UNISYS: "/unisys",
  HILLIARD: "/hilliard",
  LANDRYS: "/landrys",
  FERTTITA_ENTERTAINMENT: "/ferttitaentertainment",
  NEVADA_GOLD_MINES: "/ngm",
  UNIVERSITY_CALIFORNIA: "/uc",
  GREIF: "/greif",
  REV_SPRING: "/revspring",
  SIMPLI_SAFE: "/simplisafe",
  GENTIVA: "/gentiva",
  STANDARD_AERO: "/standardaero",
  ENLYTE: "/enlyte",
  ENERCON: "/enercon",
  LIGHTHOUSE_AUTISM_CENTER: "/abaemployeeservices",
  LIGHTHOUSE_AUTISM_CENTER_UPPERCASE: "/ABAemployeeservices",
  RED_RIVER_TECHNOLOGY: "/redrivertechnology",
  LEAD_VENTURE: "/leadventure",
  PTAPLASTICS: "/ptaplastics",
  ROCKETSOFTWARE: "/rocketsoftware",
  DWYEROMEGA: "/dwyeromega",
  L3HARRIS: "/l3harris",
  PEDIATRIC_ASSOCIATES: "/pediatricassociates",
  BANYAN: '/banyantreatmentcenter',
  WAWA: "/wawa",
  DAY_AND_ZIMMERMANN: "/dayandzimmermann",
};

export const PARTNERS_AT_ROUTES = Object.entries(ALLOWED_ROUTES).reduce(
  (result, [key, val]) => ({
    ...result,
    [val]: key,
  }),
  {}
);

export const PHONE_NUMBERS = {
  AAA: "866-586-5675",
  CROWLEY: "1-877-756-2560",
  HEBREW: "1-833-265-3169",
  KINDRED: "1-877-236-8782",
  SAFEAUTO: "1-833-696-6551",
  SAUDER: "1-844-823-4907",
  TKWW: "1-833-285-3231",
  ORIGLIO: "866-406-0115",
  AMPLIFY: "866-406-1337",
  CETERA: "844-669-8826",
  COGENTRIX: "866-897-4819",
  CITY_OF_FORT_WORTH: "888-891-7465",
  FIRST_AMERICAN_FIN: "844-593-9991",
  HUNTER_DOUGLAS: "844-284-2197",
  HONICKMAN: "866-417-0150",
  REMEDI_SENIOR_CARE: "844-836-1607",
  BOART_LONGYEAR: "855-291-3450",
  BEACON: "866-340-0021",
  LASSONDE: "866-518-0645",
  PERATON: "866-518-0527",
  HYATT_HOTELS_CORPORATION: "866-518-1744",
  GRAYMONT: "866-336-0780",
  REVERE_PLASTICS: "866-417-0148",
  WAGMAN: "866-406-0376",
  GRESHAM: "866-406-1192",
  TORY_BURCH: "866-518-0513",
  LERMAN_HOLDINGS: "866-518-1802",
  RED_THREAD: "866-386-0058",
  PAYPAL: "866-406-1309",
  ACCOLADE: "866-406-1278",
  CBIZ: "866-417-0098",
  GALLO_WINERY: "866-340-0024",
  HEALTH_AND_WELLBEING:"866-340-0024",
  CHOICE_HOTELS: "866-386-0165",
  APPTIO: "866-386-0129",
  SEVEN_ELEVEN: "866-417-0163",
  SAFRAN: "866-518-1773",
  NEVADA_GOLD_MINES: "866-417-0123",
  HILLIARD: "866-340-0135",
  UNISYS: "866-518-1829",
  FERTTITA_ENTERTAINMENT: "866-386-0131",
  UNIVERSITY_CALIFORNIA: "866-340-0063",
  GREIF: "866-406-0841",
  REV_SPRING: "866-518-1515",
  SIMPLI_SAFE: "866-518-0861",
  GENTIVA: "877-236-8782",
  STANDARD_AERO: "866-518-1807",
  ENLYTE: "866-417-0158",
  ENERCON: "866-386-0170",
  LIGHTHOUSE_AUTISM_CENTER: "866-336-0796",
  RED_RIVER_TECHNOLOGY: "866-518-1847",
  LEAD_VENTURE: "866-386-0104",
  PTAPLASTICS: "866-386-0196",
  ROCKETSOFTWARE: "866-417-0181",
  DWYEROMEGA: "866-406-1268",
  L3HARRIS: "866-386-0164",
  PEDIATRIC_ASSOCIATES: "1-866-406-0834",
  WAWA: "1-866-518-1822",
  DAY_AND_ZIMMERMANN: "1-866-417-0350",
  BANYAN: "866-417-0219"
};
