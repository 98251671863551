import React, {useEffect} from 'react'
import Card from '../../hoc/Card/Card'
import Banner from '../../hoc/Banner/Banner'
import Button from '../../hoc/Button/Button'
import Aux from '../../hoc/Aux-component'
import classes from '../../Global.module.css'
import Accordion from '../../components/Accordion/Accordion'
import {useMediaQuery} from '../../hooks'

const SevenEleven = () => {

    const isDesktop = useMediaQuery('(min-width: 768px)');

    useEffect(() => {
        document.title = "Precert Accolade | 7-Eleven"
      }, []);
      return(
    <Aux>
        <Banner>
            <h2>Things you should know</h2>
            <p><span style={{ fontWeight: 'bold' }}>VIVIO Carve Out MANDATE - Medications, Injections and Infusions</span> <br /> High cost medications, injections and infusions - including chemotherapy, must be submitted to VIVIO for review. Medications, injections and infusions that are mandated to go through VIVIO <span style={{ fontWeight: 'bold' }}>are not covered under the medical plan</span>. Please contact VIVIO at 800-470-4034 to initiate precertification. If you have questions regarding this plan requirement, please contact Accolade Provider Services at 866-417-0163.</p>
            <p><span style={{ fontWeight: 'bold' }}>OneImaging MANDATE - CT, MRI and MRA Procedures</span> <br /> 7-Eleven members are required to use OneImaging’s care management program for CT, MRI and MRA procedures <span style={{ fontWeight: 'bold' }}>or the service will not be covered</span>. Prior authorization must be submitted through OneImaging. Please contact OneImaging Services Team at 833-619-0837 to initiate precertification. If you have questions regarding this plan requirement, please contact Accolade Provider Services at 866-417-0163.</p>
            <p><span style={{ fontWeight: 'bold' }}>Physical Therapy REQUIREMENT - CT, MRI and MRA Procedures with MSK Diagnosis</span> <br /> For MSK-related conditions, the member is required to have 3 PT visits with Vori Health (Vendor) before having a CT, MRI <span style={{ fontWeight: 'bold' }}>or MRA or the service will not be covered</span>. Please have the member contact Accolade Member Services at 866-336-0735 to understand their benefits and coverage provisions. If you have questions regarding this plan requirement, please contact Accolade Provider Services at 866-417-0163.</p>
            <p><span style={{ fontWeight: 'bold' }}>Lantern Centers of Excellence MANDATE - Bariatric, Joint and Spine Procedures</span> <br /> 7-Eleven requires bariatric, joint and spine surgeries to be performed within the Lantern network of hospitals and ambulatory surgery centers, <span style={{ fontWeight: 'bold' }}>or the surgery will not be covered</span>. Please have the member contact Accolade Member Services at 866-336-0735 to understand their benefits and coverage provisions. If you have questions regarding this plan requirement, please contact Accolade Provider Services at 866-417-0163.</p>
            <p><span style={{ fontWeight: 'bold' }}>If not a mandate or plan requirement above, see precertification list below for services that require precertification. </span></p>
        </Banner>
        <div className={classes.Global}>
            <Card style={styles.container(isDesktop)}>
                <h1>7-Eleven Precertification List <br />Effective 1/1/2025</h1>
                <p>Pre-certification is a determination of medical necessity only, and <span>does not involve matters of claim payment, eligibility, coverage and the type and/or availability of benefits.</span>
                </p>
                <p>The establishment and construct of a precertification list is solely the responsibility of customer and/or the applicable plan sponsor (and not Accolade).
                The design and implementation of a precertification list should be made only after obtaining the advice of customer’s legal and benefit professionals,
                to include customer’s full review of applicable health and welfare benefit plan terms and conditions as well as any applicable laws (for example, the Employee Retirement Income Security Act of 1974, and the Health Parity and Addiction Equity Act of 2008, as amended).
                Accolade will have no responsibility for reviewing applicable plan documents, advising customer with respect to its precertification list or for customer’s compliance with applicable laws related to the same.
                No representation or warranty is given with respect to this document, which is offered as-is.</p>
                <div style={{ fontWeight: 'bold', marginBottom: '12px' }}>Precertification  requirements  should  be  implemented  only  for  those  services  listed  within  the  Summary  Plan Description as requiring Prenotification or Precertification and not defined as excluded.</div>
                <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>UM Carve Out</div>
                <div style={{ fontWeight: 'bold' }}>High Cost Medications (infusions, injections and chemotherapy) - if medication is on ‘VIVIO High Cost Durg List” - refer to VIVIO at 800-470-4034</div>
                <Accordion label='All Inpatient Admissions'>
                    <ul>
                        <li>Acute</li>
                        <li>Long-Term Acute Care</li>
                        <li>Rehabilitation</li>
                        <li>Mental Health / Substance Use Disorder</li>
                        <li>Transplant</li>
                        <li>Skilled Nursing Facility</li>
                        <li>Residential Treatment Facility</li>
                        <li>Obstetric – Prenotification only (precertification only required if days exceed Federal mandate)</li>
                    </ul>
                </Accordion>
                <div style={{ fontWeight: 'bold' }}>Precertification for Inpatient and Outpatient procedures that could be considered Experimental / Investigational</div>
                <Accordion label='Outpatient and Physician – Surgery' subLabel={'Lantern COE'}>
                    <p>Services must be approved by and rendered by Lantern COE, Limited to specific CPT codes; please call Accolade Provider Services at 866-417-0163 for additional information.</p>
                    <ul>
                        <li>Bariatric</li>
                        <ul>
                            <li>Duodenal Switch</li>
                            <li>Gastric Bypass</li>
                            <li>Lap Band</li>
                            <li>Sleeve Gastrectomy</li>
                        </ul>
                        <li>Joint Replacement</li>
                        <ul>
                            <li>Hip</li>
                            <li>Knee</li>
                            <li>Wrist</li>
                            <li>Ankle</li>
                            <li>Elbow</li>
                            <li>Shoulder</li>
                        </ul>
                        <li>Spine</li>
                        <ul>
                            <li>Spinal Fusion</li>
                            <li>Cervical Disc Fusion</li>
                            <li>Laminectomy</li>
                            <li>Laminotomy</li>
                            <li>Lumbar Interbody Fusion
                            </li>
                        </ul>
                    </ul>
                </Accordion>
                <Accordion label='Outpatient and Physician – Surgery' subLabel={'UM by Accolade'}>
                    <ul>
                        <li>Procedures not included on the Lantern COE CPT code list for the following surgeries:</li>
                        <ul>
                            <li>Bariatric</li>
                        </ul>
                        <li>All joint replacement surgeries</li>
                        <li>Thyroidectomy, Partial or Complete</li>
                        <li>Prostate or Ovary Removal – include Open Prostatectomy / Oopherectomy</li>
                        <li>Back Surgeries and hardware related to surgery</li>
                        <li>Osteochondral Allograft, knee</li>
                        <li>Hysterectomy (including prophylactic)</li>
                        <li>Autologous chondrocyte implantation, Carticel</li>
                        <li>Transplant (excluding cornea)</li>
                        <li>Balloon sinuplasty</li>
                        <li>Sleep apnea related surgeries, limited to:
                            <ul>
                                <li>Radiofrequency ablation (Coblation, Somnoplasty)</li>
                                <li>Uvulopalatopharyngoplasty (UPPP) (including laser-assisted procedures)</li>
                            </ul>
                        </li>
                        <li>Potentially Cosmetic Procedures, including but not limited to:
                            <ul>
                                <li>Abdominoplasty</li>
                                <li>Blepharoplasty</li>
                                <li>Cervicoplasty (neck lift)</li>
                                <li>Facial skin lesions (Photo therapy, laser therapy - excluding MOHS)</li>
                                <li>Hernia repair, abdominal and incisional (only when associated with a cosmetic procedure)</li>
                                <li>IDET (thermal intradiscal procedures)</li>
                                <li>Liposuction/lipectomy</li>
                                <li>Mammoplasty, augmentation and reduction (including removal of implant)</li>
                                <li>Mastectomy (including gynecomastia and prophylactic)</li>
                                <li>Morbid obesity procedures</li>
                                <li>Orthognathic procedures (e.g. Genioplasty, LeFort osteotomy, Mandibular ORIF, TMJ)</li>
                                <li>Otoplasty</li>
                                <li>Panniculectomy</li>
                                <li>Rhinoplasty</li>
                                <li>Rhytidectomy</li>
                                <li>Scar revisions</li>
                                <li>Septoplasty</li>
                                <li>Varicose vein surgery/sclerotherapy</li>
                            </ul>
                        </li>
                    </ul>
                </Accordion>
                <Accordion label='Outpatient and Physician – Diagnostic Services'>
                    <ul>
                        <li>MRI (Please see “Things you should know section”)</li>
                        <li>MRA (Please see “Things you should know section”)</li>
                        <li>CT Scans (Please see “Things you should know section”)</li>
                        <li>PET</li>
                        <li>Capsule endoscopy</li>
                        <li>Genetic Testing (including BRCA)</li>
                        <li>Sleep Study (facility based)</li>
                    </ul>
                </Accordion>
                <Accordion label='Outpatient and Physician – Continuing Care Services'>
                    <ul>
                        <li>Radiation Therapy</li>
                        <li>Hyperbaric Oxygen</li>
                        <li>Home Health Care related to occupational therapy, physical therapy, speech therapy and skilled nursing care done in the home</li>
                        <li>Durable Medical Equipment, limited to electric/motorized scooters or wheelchairs and pneumatic compression devices</li>
                        <li>DME {'>'}$1K (excludes disposable supplies)</li>
                    </ul>
                </Accordion>
                <Accordion label='Medical Evacuation'>
                    <ul>
                        <li>Air Ambulance for non-emergent transportation</li>
                    </ul>
                </Accordion>
            </Card>
            <h1>Ready to start a new pre-certification request?</h1>
            <Button onClick={() => {
                window.location.href = 'https://accolade.getprecert.com/(S(cbykgvb0qn2hh32kfks0etan))/default.aspx';
            }}
                style={{ cursor: 'pointer' }}
                id={"start"}>Start Request</Button>
        </div>
    </Aux>
)}

const styles = {
    container: isDesktop => ({
        padding: isDesktop ? '80px 170px':'10px 40px 30px 29px',
        marginBottom: isDesktop ? '150px':'90px'
    })
  };

export default SevenEleven;
